export const vinantic_french_traduction = {
  title: "Vinantic",
  description: {
    head: "Bienvenue sur Vinantic, le lieu de rencontre des amateurs de vin et des collectionneurs ! Nous avons rassemblé les meilleurs millésimes de 1940 à nos jours pour vous offrir une expérience unique et mémorable.",
    content_1:
      "Imaginez une cave remplie de bouteilles de millésimes rares, chacune racontant une histoire, ou un moment précieux de votre vie. Ou encore, imaginez offrir une bouteille de vin millésimée à un ami pour célébrer un événement spécial, ou encore, collectionner des bouteilles de vin pour les admirer comme de véritables œuvres d'art.",
    content_2:
      "Notre sélection de vins, est un véritable trésor, un choix éclectique pour répondre à tous les goûts, des vins rouges corsés aux vins blancs frais ou liqoureux en passant par des vins rosés fruités. Mais n'oubliez pas, une bouteille de vin, c'est bien plus qu'un simple breuvage, c'est un objet d'art, une pièce précieuse, un témoin des années passées.",
  },
  warnings: {
    no_wines_available: "Aucun millésime n'est disponible",
  },
  banner: {
    upload_pdf: "Télécharger le catalogue au format PDF.",
    sorted_list: "La liste ci-dessous a été triées.",
    no_sorted_list: "La liste ci-dessous n'a pas été triées.",
    no_available_list: "Aucune bouteille n'est disponible.",
    available_list: "{{ totalWines }} bouteilles sont disponibles. ",
    page_info: "Vous êtes sur la page {{currentPage}} du catalogue. "
  },
  bottle: {
    castle: "Château",
    stock: "En stock :",
    price: "Prix unitaire :",
    unit: "€",
    quality: "Qualité : ",
    description: "Description :",
    ref: "Référence :",
  },
  general: {
    loadingMessage: "En cours de chargement...",
    errorMessage: "Une erreur est survenue"
  }
};
